import React from "react"
import { Link } from "gatsby"
import Layout from '../components/layout'
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"

const Home = ({ user }) => {
    return <p>Hi, {user.name ? user.name : "friend"}!</p>
  }
const Settings = () => <p>Settings</p>
const Billing = () => <p>Billing</p>

const Account = (props) => {
    if (!isAuthenticated()) {
      login()
      return <p>Redirecting to login...</p>
    }
  
    const user = getProfile()
  
    return (
      <Layout location={props.location}>

        <h2>Temporalmente en construccion. </h2>
      </Layout>      
    )
  }

export default Account